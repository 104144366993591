.backToTop {
   position: fixed;
   right: -1px;
   bottom: 90px;
   height: 50px;
   font-size: 3rem;
   z-index: 999;
}

.backToTop button {
   outline: none;
   border: none;
   cursor: pointer;
   background: none;
   padding: 20px;
}

@media screen and (max-width: 800px) {
   .backToTop {
      right: -1.5px;
      bottom: 90px;
      font-size: 2.75rem;
   }
}
